import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Location from "../components/Location"
import Section from "../components/Section"
import {
  TextContent
} from '../styles/utility'

const BookingsPage = () => (
  <Layout>
    <SEO title="Book your appointment for Dental Services in Bella Vista &amp; Norwest" description="Are you looking for dental treatment in Bella Vista or Norwest? We offer a variety of general and cosmetic dentistry treatments. Book an appointment today." 
      noFollow={true} 
    />
    <Section pageTitle="Book your appointment">
        <TextContent>
            <p>Book your appointment below.</p>
            <iframe src="https://apac.dentalhub.online/soe/new/Norwest%20Dental%20Care?pid=OARDA01" width="100%" height="800"></iframe>
       </TextContent>
    </Section>
    <Section>
      <Location />
    </Section>
  </Layout>
)

export default BookingsPage
